// src/features/activity/activityList.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActivity } from "./activitySlice";
import { activityDetails } from "./activityService";
import "./activityList.scss";
import Card from "../../components/Card";
import Table from "../../components/Table/Table";
import Button from "../../components/Button";
import AddActivityModal from "./addActivityModal/AddActivityModal";
import { EditLogo } from "../../assets/images/svg";
import EditActivityModal from "./addActivityModal/EditActivityModal";
import { useNavigate } from "react-router-dom";

const ActivityList = () => {
  console.log("inside activity list page");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [callApi, setCallApi] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivities = async () => {
      const data = await activityDetails(token);
      dispatch(setActivity(data));
    };

    fetchActivities();
    setCallApi(true);
  }, [dispatch, token, callApi]);

  const activities = useSelector((state) => state.activity.activities);
  const user_details = useSelector((state) => state.login.profile)

  const handleEditClick = (activity) => {
    setSelectedActivity(activity);
    setShowEditModal(true);
  };

  const activityStatusList = [
    "ALL",
    "SCHEDULED",
    "INPROGRESS",
    "COMPLETED",
    "SUBMITTED",
    "APPROVED",
    "PUBLISHED",
  ];

  const columns = [
    {
      name: "Class",
      selector: (row) => row.grade_id_rslv,
      sortable: true,
      cell: (row) => `${row.grade_id_rslv}`,
      width: "90px",
    },
    {
      name: "Section",
      selector: (row) => row.section_id_rslv,
      cell: (row) => row.section_id_rslv,
      sortable: true,
      width  :"auto",
      maxWidth : "100px"
    },
    {
      name: "Activity Date",
      selector: (row) => row.start_date,
      sortable: true,
    },
    {
      name: "Activity Name",
      selector: (row) => row.activity_id_rslv,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.activity_id_rslv}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        >
          {row.activity_id_rslv}
        </span>
      ),
    },
    {
      name: "Status updated on",
      selector: (row) => row.modified_on,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      width: "auto",
      maxWidth: "160px",
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button className="btn" onClick={() => handleEditClick(row)}>
          <EditLogo />
        </button>
      ),
    },
  ];

  if (user_details?.role_id == 2){
    columns.unshift({
      name: "School",
      selector: (row) => row.school_id_rslv, // Replace with the actual field
      sortable: true,
      // cell: (row) => <span>{row.school_id_rslv}</span>, // Customize as needed
      width: "auto",
      maxWidth: "150px",
    });
  }

  const handleShow = () => {
    console.log("trueeee");
    setShowModal(true);
  };

  return (
    <div className="activity-page">
      {/* <div className="activity-taskbar mb-2">
        <Button label="Add Activity" onClick={()=>handleShow()} />
      </div> */}
      <Card>
        <Table
          entity="activity"
          data={activities}
          columns={columns}
          statusList={activityStatusList}
          onAddClick={handleShow}
        />
      </Card>
      {showModal && (
        <AddActivityModal
          displayModal={showModal}
          setShowModal={setShowModal}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      )}
      {showEditModal && (
        <EditActivityModal
          displayModal={showEditModal}
          setShowModal={setShowEditModal}
          activityData={selectedActivity}
          callApi={callApi}
          setCallApi={setCallApi}
        />
      )}
    </div>
  );
};

export default ActivityList;
