import React, { useState } from "react";
import FormInput from "../../../../../components/Form/FormInput";
import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import { AddLogo, DeleteLogo } from "../../../../../assets/images/svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCourseMaster,
  getCircleCourseDropdown,
  updateCourseMasterCourse,
} from "../../courseMasterService";
import { useEffect } from "react";
import { schoolMasterDetails } from "../../../../schools/school/schoolMasterService";
import { formatDateString } from "../../../../../utils/formatter";

const CourseMasterDetails = ({}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [courseDropdownOptions, setCourseDropdownOptions] = useState([]);
  const [orgDropdownOptions, setOrgDropdownOptions] = useState([]);
  const [courseHighlightOptions, setCourseHighlightOptions] = useState([
    { value: "Certification", label: "Certification" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "1 hour", label: "1 Hour" },
    { value: "2 hours", label: "2 Hours" },
    { value: "3 hours", label: "3 Hours" },
  ]);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const allCourses = useSelector((state) => state.courseMaster.courses);
  const selectedCourse = allCourses.find(
    (course) => course.id === parseInt(id)
  );
  console.log(selectedCourse);

  const maxId = allCourses.reduce((max, course) => {
    return course.id > max ? course.id : max;
  }, 0);

  const isEditable = useSelector(
    (state) => state.courseMaster.isCourseEditable
  );

  const isEditEntry = useSelector((state) => state.courseMaster.isEditEntry);
  const isNewEntry = useSelector((state) => state.courseMaster.isNewEntry);

  const idValue = isEditEntry
    ? selectedCourse?.id
    : isNewEntry
    ? maxId + 1
    : selectedCourse?.id;

  // Initial form values
  const [values, setValues] = useState({
    course_id: idValue,
    name: selectedCourse?.name ?? "",
    type: selectedCourse?.type ?? "",
    author: selectedCourse?.author ?? "",
    school_id: selectedCourse?.school_id ?? "",
    circle_course_space: selectedCourse?.circle_space_id ?? "",
    preview_video: "",
    preview_image: "",
    course_highlights: selectedCourse?.description_list ?? [],
    status: selectedCourse?.status ?? "",
    desctiption: selectedCourse?.description ?? "",
    is_paid: selectedCourse?.is_paid ?? "",
    price: selectedCourse?.price ?? "",
    // assessment: 1,
    has_assessment: selectedCourse?.has_assessment ?? undefined,
    is_certified: selectedCourse?.is_certified ?? undefined,
    dummy_certificate: "",
    certificate_html: selectedCourse?.certificate_html ?? "",
    learnings: selectedCourse?.description_learnings ?? [""],
    video_lessons: selectedCourse?.lesson_count ?? "",
    physical_material: "",
    no_of_assessments: selectedCourse?.no_of_assessments ?? "",
    certification_of_completion: "",
  });

  useEffect(() => {
    const fetchCourseDropdownOptions = async () => {
      try {
        const response = await getCircleCourseDropdown();
        // setCourseDropdownOptions(response);
        let dropdownOptions = [];
        dropdownOptions.push({ value: "", label: "Select course" });
        response.forEach((course) => {
          dropdownOptions.push({
            value: course.id,
            label: course.name,
          });
        });
        setCourseDropdownOptions(dropdownOptions);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchOrgDropdownOptions = async () => {
      try {
        const response = await schoolMasterDetails();
        console.log(response);
        let dropdownOptions = [];
        dropdownOptions.push({ value: "", label: "Select organization" });
        response.forEach((org) => {
          dropdownOptions.push({
            value: org.id,
            label: org.name,
          });
        });
        setOrgDropdownOptions(dropdownOptions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrgDropdownOptions();
    fetchCourseDropdownOptions();
  }, []);

  const inputs = [
    {
      id: 1,
      name: "course_id",
      type: "text",
      label: "Id",
      disabled: true,
    },
    {
      id: 2,
      name: "name",
      type: "text",
      label: "Name",
      required: !isEditEntry,
      errorMessage: "Please enter a name.",
      disabled: !isEditable,
    },
    {
      id: 3,
      name: "type",
      type: "select",
      label: "Type",
      placeholder: "Select a type",
      options: [
        { value: "", label: "Select type" },
        { value: "open", label: "Open" },
        { value: "school", label: "School" },
      ],
      value: values.type,
      required: !isEditEntry,
      errorMessage: "Please select a type.",
      disabled: !isEditable,
    },
    {
      id: 4,
      name: "author",
      type: "text",
      label: "Author",
      required: !isEditEntry,
      errorMessage: "Please enter an author.",
      disabled: !isEditable,
    },
    {
      id: 5,
      name: "school_id",
      type: "select",
      label: "Organization",
      placeholder: "Select an organization",
      options: orgDropdownOptions,
      required: !isEditEntry,
      errorMessage: "Please enter an organization.",
      disabled: !isEditable,
    },
    {
      id: 6,
      name: "circle_course_space",
      type: "select",
      label: "Circle Course Space",
      placeholder: "Select a circle course space",
      options: courseDropdownOptions,
      required: !isEditEntry,
      errorMessage: "Please enter a circle course space.",
      disabled: !isEditable,
    },
    {
      id: 7,
      name: "preview_video",
      type: "file",
      label: "Preview Video",
      required: !isEditEntry,
      errorMessage: "Please select a preview video file.",
      disabled: !isEditable,
    },
    {
      id: 8,
      name: "preview_image",
      type: "file",
      label: "Cover Image",
      required: !isEditEntry,
      errorMessage: "Please select a cover image file.",
      disabled: !isEditable,
    },
    {
      id: 9,
      name: "course_highlights",
      type: "select",
      multiple: true,
      placeholder: "Select course highlights",
      // value: [{ value: "English", label: "English" }],
      defaultValue: selectedCourse?.description_list.map((highlight) =>
        courseHighlightOptions.find((option) => option.value === highlight)
      ),
      options: courseHighlightOptions,
      label: "Course Highlights",
      required: !isEditEntry,
      onChange: (selectedOptions, e) => {
        setValues({
          ...values,
          [e.name]: selectedOptions.map((option) => option.value),
        });
      },
      disabled: !isEditable,
    },
    {
      id: 10,
      name: "status",
      type: "select",
      label: "Status",
      options: [
        { value: "", label: "Select status" },
        { value: "draft", label: "Draft" },
        { value: "published", label: "Published" },
      ],
      required: !isEditEntry,
      errorMessage: "Please select a status.",
      disabled: !isEditable,
    },
    {
      id: 11,
      name: "desctiption",
      type: "textarea",
      label: "Course Description",
      required: !isEditEntry,
      errorMessage: "Please enter a course description.",
      disabled: !isEditable,
    },

    // {
    //   id: 2,
    //   name: "email",
    //   type: "email",
    //   label: "Email",
    //   errorMessage: "Please enter a valid email.",
    //   required: !isEditEntry,
    //   disabled: true,
    // },
    // {
    //   id: 3,
    //   name: "country",
    //   type: "select",
    //   label: "Country",
    //   options: [
    //     { value: "us", label: "United States" },
    //     { value: "ca", label: "Canada" },
    //     { value: "uk", label: "United Kingdom" },
    //   ],
    //   multiple: true,
    //   onChange: (selectedOptions, e) => {
    //     setValues({
    //       ...values,
    //       [e.name]: selectedOptions.map((option) => option.value),
    //     });
    //   },
    // },
    // {
    //   id: 4,
    //   name: "gender",
    //   type: "radio",
    //   label: "Gender",
    //   options: [
    //     { value: "male", label: "Male" },
    //     { value: "female", label: "Female" },
    //   ],
    // },
    // {
    //   id: 5,
    //   name: "file",
    //   type: "file",
    //   label: "File",
    //   disabled: true,
    // },
    // {
    //   id: 6,
    //   name: "terms",
    //   type: "checkbox",
    //   label: "Terms and Conditions",
    // },
  ];

  const advancedDetailsInputs = [
    {
      id: 12,
      name: "is_paid",
      type: "select",
      label: "Pricing",
      options: [
        { value: "", label: "Select pricing" },
        { value: 0, label: "Free" },
        { value: 1, label: "Paid" },
      ],
      required: !isEditEntry,
      disabled: !isEditable,
    },
    {
      id: 13,
      name: "price",
      type: "text",
      label: "Price",
      required: !isEditEntry,
      errorMessage: "Please enter a price.",
      disabled: !isEditable,
    },
    {
      id: 14,
      name: "has_assessment",
      type: "radio",
      label: "Assessment",
      options: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      required: !isEditEntry,
      disabled: !isEditable,
      // value: selectedCourse?.has_assessment ?? undefined,
    },
    {
      id: 15,
      name: "is_certified",
      type: "radio",
      label: "Certificate",
      options: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      required: !isEditEntry,
      disabled: !isEditable,
      // value: selectedCourse?.is_certified ?? undefined,
    },
    {
      id: 16,
      name: "dummy_certificate",
      type: "file",
      label: "Dummy Certificate",
      disabled: !isEditable,
    },
    {
      id: 17,
      name: "certificate_html",
      type: "textarea",
      label: "Certificate HTML",
      disabled: !isEditable,
    },
  ];

  const inclusionsInputs = [
    {
      id: 18,
      name: "video_lessons",
      type: "text",
      label: "No. of Video Lessons",
      required: !isEditEntry,
      errorMessage: "Please enter no. of video lessons.",
      disabled: !isEditable,
    },
    {
      id: 19,
      name: "physical_material",
      type: "select",
      label: "Physical Material",
      options: [
        { value: "", label: "Select physical material" },
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      disabled: !isEditable,
    },
    {
      id: 20,
      name: "no_of_assessments",
      type: "text",
      label: "No. of Assessment",
      required: !isEditEntry,
      errorMessage: "Please enter no. of assessment.",
      disabled: !isEditable,
    },
    {
      id: 21,
      name: "certification_of_completion",
      type: "select",
      label: "Completion Certificate",
      options: [
        { value: "", label: "Select completion certificate" },
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      disabled: !isEditable,
    },
  ];

  const onChange = (e) => {
    if (e.target.type === "file") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setValues({
        ...values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //make formData
    const formData = new FormData();

    let toSend = values;
    !isEditEntry && delete toSend["course_id"];

    console.log(toSend["learnings"]);
    console.log(toSend["course_highlights"]);

    Object.keys(toSend).forEach((key) => {
      const value = toSend[key];

      if (Array.isArray(value)) {
        // Handle array: append each item individually
        // value.forEach((item) => formData.append(key, item));
        formData.append(key, JSON.stringify(value));
      } else if (value instanceof File) {
        // Handle single file
        formData.append(key, value);
      } else if (value instanceof FileList) {
        // Handle multiple files (FileList)
        Array.from(value).forEach((file) => formData.append(`${key}[]`, file));
      } else if (value !== null && value !== undefined && value !== "") {
        // Handle other data types (strings, numbers, etc.)
        formData.append(key, value);
      }
    });

    // log formData pairs
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ": " + pair[1]);
    // }

    try {
      setIsSaving(true);
      const response = isEditEntry
        ? await updateCourseMasterCourse(formData)
        : await addToCourseMaster(formData);

      console.log(">>>>>>>>>>>>>>>>>", response);

      if (response.status) {
        !isEditEntry
          ? alert("Course added successfully!")
          : alert("Course updated successfully!");
        navigate(1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card>
      <div className="p-3 card-lg">
        {isSaving ? (
          <h4>Saving...</h4>
        ) : (
          <form onSubmit={handleSubmit} className="row">
            {FormInputRenderer(inputs, values, onChange)}

            {separator("Advanced Details")}

            {FormInputRenderer(advancedDetailsInputs, values, onChange)}

            {separator("Learnings")}

            <Learnings
              values={values}
              setValues={setValues}
              disabled={!isEditable}
            />

            {separator("Inclusions")}

            {FormInputRenderer(inclusionsInputs, values, onChange)}
            {isEditable && (
              <div className="row mt-5">
                <Button
                  label={isEditEntry ? "Update" : "Save"}
                  backgroundColor={"#f6921e"}
                  textColor={"#fff"}
                  className="col-12 offset-md-10 col-md-2"
                  type="submit"
                />
              </div>
            )}
          </form>
        )}
      </div>
    </Card>
  );
};

export const separator = (title) => {
  return (
    <>
      <div className="row  align-items-center my-4">
        <h5 className="col-auto">{title}</h5>
        <hr className="col-10 m-0" />
      </div>
    </>
  );
};

export const FormInputRenderer = (inputList, values, onChange) => {
  return inputList.map((input) => (
    <FormInput
      key={`${input.name}_${input.id}`}
      {...input}
      value={
        input.name == "date_time"
          ? formatDateString(values[input.name])
          : values[input.name]
      }
      onChange={input.onChange ?? onChange}
      className="col-12 col-md-6"
    />
  ));
};

export const Learnings = ({ values, setValues, disabled }) => {
  return (
    <>
      {values.learnings.map((learning, i) => (
        <div key={`learning_${i}`} className="row align-items-center">
          <FormInput
            key={`learning_${i}`}
            name={`learnings_${i}`}
            type="text"
            value={learning}
            onChange={(e) => {
              const newLearnings = [...values.learnings];
              newLearnings[i] = e.target.value;
              setValues({
                ...values,
                learnings: newLearnings,
              });
            }}
            className="col-11"
            required={true}
            disabled={disabled}
          />

          <div
            className={`col-auto ${
              i !== values.learnings.length - 1 ? "opacity-0" : ""
            }`}
            onClick={() =>
              !disabled &&
              i === values.learnings.length - 1 &&
              setValues({
                ...values,
                learnings: [...values.learnings, ""],
              })
            }
          >
            <AddLogo />
          </div>
          {!disabled && values.learnings.length > 1 && (
            <div
              className="col-auto "
              onClick={() =>
                setValues({
                  ...values,
                  learnings: values.learnings.filter((_, index) => index !== i),
                })
              }
            >
              <DeleteLogo />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default CourseMasterDetails;
