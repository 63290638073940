import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "./table.scss"; // Custom styles
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { setBreadCrumbHeader } from "../../Layout/SubHeader/breadCrumbSlice";
import {
  setActivityEditable,
  setFieldsDisable,
} from "../../features/master/activity/activityMasterSlice";

const Table = ({
  entity,
  data,
  columns,
  statusList,
  onAddClick,
  buttonLabel = "+ Add New",
  clickableRow = true,
  onRowClick = () => {},
  selectableRows = false,
  isServerSidePagination = false,
  setPage = () => {},
  setRowsPerPage = () => {},
  paginationTotalRows,
  hasAddBtn = true,
  hasActionButtons = false,
  actionButtons = {
    positiveActionLabel: "Approve",
    negativeActionLabel: "Reject",
    section_completed: false,
    section_rejected: false,
    rejectedMessage: "",
    completedMessage: "",
    negativeAction: () => {},
    positiveAction: () => {},
  },
  isSupportTickets = false,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    dispatch(setBreadCrumbHeader(row.name));
    clickableRow && onRowClick(row);
    if (entity == "activity-master") {
      dispatch(setFieldsDisable(true));
      dispatch(setActivityEditable(true));
    }
    navigate(`/${entity}/${row.id}`); // Replace `/entity/` with a dynamic path if needed
  };

  const [filterStatus, setFilterStatus] = useState(
    isSupportTickets ? "UPCOMING" : "ALL"
  );
  const filteredData =
    filterStatus === "ALL"
      ? isSupportTickets
        ? data.filter(
            (item) =>
              item.status.toLowerCase() !== "completed" &&
              item.status.toLowerCase() !== "cancelled"
          )
        : data
      : filterStatus === "COMPLETED" && isSupportTickets
      ? data
          .filter(
            (item) => item.status.toLowerCase() === filterStatus.toLowerCase()
          )
          .slice()
          .reverse()
      : filterStatus === "UPCOMING" && isSupportTickets
      ? data.filter(
          (item) =>
            item.status.toLowerCase() !== "completed" &&
            item.status.toLowerCase() !== "cancelled"
        )
      : data.filter(
          (item) => item.status.toLowerCase() === filterStatus.toLowerCase()
        );

  return (
    <div className="table-contents">
      {statusList && (
        <div className="tabs-header position-relative z-0">
          <div className="status-tabs">
            {statusList.map((status) => (
              <div
                key={status}
                className={`tab ${filterStatus === status ? "active" : ""}`}
                onClick={() => setFilterStatus(status)}
              >
                {status}{" "}
                {status === "ALL" || status === "UPCOMING"
                  ? null
                  : `(${
                      data.filter(
                        (item) =>
                          item.status.toLowerCase() === status.toLowerCase()
                      ).length
                    })`}
              </div>
            ))}
          </div>
          {hasAddBtn && (
            <div>
              <Button
                label={buttonLabel}
                onClick={onAddClick}
                backgroundColor="#EA983E"
                textColor="white"
              />
            </div>
          )}
          {hasActionButtons ? (
            <div className="">
              <Button
                label={actionButtons.positiveActionLabel}
                onClick={() => {
                  actionButtons.positiveAction &&
                    actionButtons.positiveAction();
                }}
                backgroundColor="#EA983E"
                textColor="white"
                className="mx-2"
              />
              <Button
                label={actionButtons.negativeActionLabel}
                onClick={() => {
                  actionButtons.negativeAction &&
                    actionButtons.negativeAction();
                }}
                textColor="white"
                className="me-3 btn-danger"
              />
            </div>
          ) : (
            <>
              {actionButtons.section_completed && (
                <span className="text-end fs-6 align-self-center badge bg-info me-3">
                  {actionButtons.completedMessage}
                </span>
              )}
              {actionButtons.section_rejected && (
                <span className="text-end fs-6 align-self-center badge bg-danger me-3">
                  {actionButtons.rejectedMessage}
                </span>
              )}
            </>
          )}
        </div>
      )}
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        striped
        onRowClicked={(row) => clickableRow && handleRowClick(row)}
        selectableRows={selectableRows}
        paginationServer={isServerSidePagination}
        onChangeRowsPerPage={setRowsPerPage}
        onChangePage={setPage}
        paginationTotalRows={paginationTotalRows}
      />
    </div>
  );
};

export default Table;
