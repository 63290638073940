import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React, { useEffect, useState } from "react";
const DropdownButton = ({
  dropDownBtnOptions = [
    {
      label: "Join",
      onClick: () => {
        console.log("Join");
      },
    },
    {
      label: "Reschedule",
      onClick: () => {
        console.log("Reschedule");
      },
    },
    {
      label: "Cancel",
      onClick: () => {
        console.log("Cancel");
      },
    },
  ],
}) => {
  const [selectedOption, setSelectedOption] = useState(dropDownBtnOptions[0]);
  const [isValidClick, setIsValidClick] = useState(false);

  useEffect(() => {
    if (isValidClick) {
      selectedOption.onClick();
    }
  }, [selectedOption]);

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-primary ticket-action-button"
        onClick={() => {
          setIsValidClick(true);
          selectedOption.onClick();
        }}
      >
        {selectedOption.label}
      </button>
      <button
        type="button"
        className="btn btn-primary dropdown-toggle dropdown-toggle-split ticket-action-dropdown-button "
        data-bs-toggle="dropdown"
        data-bs-offset="-94,0"
        aria-expanded="false"
      >
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu ticket-action-select z-5">
        {dropDownBtnOptions.map(
          (option, index) =>
            index !== 0 && (
              <li key={index}>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    option.onClick();
                  }}
                >
                  {option.label}
                </a>
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default DropdownButton;
