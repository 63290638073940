import axios from "axios";
import { DEV_BASE_URL } from "../../utils/constants/routes";

export const getTicketsAPI = async (page, page_size = 10) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/api/v1/admin/get_appointments`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        page,
        page_size,
      },
    }
  );

  return response.data;
};

export const getFreeBusySlots = async (time_min, time_max, calendar_type) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/calendar/freebusy`,
    {
      time_min,
      time_max,
      calendar_type,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const cancelAppointment = async (appointment_id, event_id) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/admin/cancel_appointment`,
    {
      appointment_id,
      event_id,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};

export const rescheduleAppointment = async (body) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/v1/admin/reschedule_appointment`,
    body,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return response.data;
};
