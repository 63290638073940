import { useState } from "react";
import { renderTab } from "../../../../utils/tabRenderer";
import CourseMasterDetail from "./detail/CourseMasterDetail";
import CourseMasterContent from "./content";
import CourseSyl from "./content/CourseSyl";
import { useSelector } from "react-redux";

const AddCourse = () => {
  const [activeTab, setActiveTab] = useState("details");
  const isNewEntry = useSelector((state) => state.courseMaster.isNewEntry);

  const tabList = isNewEntry ? ["details"] : ["details", "content"];

  const renderTabContent = (tabName) => {
    switch (tabName) {
      case tabList[0]:
        return <CourseMasterDetail />;
      case tabList[1]:
        return <CourseSyl />;
      default:
        return <CourseMasterDetail />;
    }
  };

  return (
    <div className="add__course--page">
      <div className="tabs page-tab">
        {tabList.map((tab, index) =>
          renderTab(tab, index, activeTab, setActiveTab)
        )}
      </div>

      {renderTabContent(activeTab)}
    </div>
  );
};

export default AddCourse;
