import React, { useEffect, useState } from "react";
import "./courseDetails.scss";
import Button from "../../../components/Button";
import { useParams } from "react-router-dom";
import {
  assessmentDetails,
  assessmentDetailsSYLQuestions,
  assessmentDetailsV2,
  assessmentStatusUpdate,
  getViva,
  vivaSchedule,
} from "./courseDetailsService";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssessmentDetailsWithSYLQuestions,
  setAssessments,
  setAssessmentsMultipleFileTypes,
} from "./courseDetailsSlice";
import {
  AttachmentLogo,
  CalenderLogo,
  TimeLogo,
  TimeZoneLogo,
  CrossLogo,
} from "../../../assets/images/svg";
import successGif from "../../../assets/images/media/success.gif";
import SYLListing from "./SYLListing/SYLListing";
import AlertWithIcon from "../../../components/Alerts/AlertWithIcon";
import VivaList from "../courseDetails/VivaListing/VivaListing";

const CourseDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("Assesment");
  const [status, setStatus] = useState("approved");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [mode, setMode] = useState("");
  const [link, setLink] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [assessmentStage, setAssessmentStage] = useState("video");
  const [apiCall, SetApiCall] = useState(new Date());
  const [errors, setErrors] = useState({
    endDateError: "",
    linkError: "",
  });

  let assessmentlist;

  useEffect(() => {

    const fetchCourseAssessmentDetailsWithSYLQuestions = async () => {
      const data = await assessmentDetailsSYLQuestions(token, id);
      dispatch(setAssessmentDetailsWithSYLQuestions(data.data));
    };

    fetchCourseAssessmentDetailsWithSYLQuestions();
  }, [dispatch, token, status, apiCall]);

  assessmentlist = useSelector(
    (state) => state.assessmentDetail.assessmentDetails
  );

  return (
    <div className="course-details-page">
      <div className="tabs page-tab">
        <div
          className={`tab ${activeTab === "details" ? "tab-active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </div>
        <div
          className={`tab ${activeTab === "Assesment" ? "tab-active" : ""}`}
          onClick={() => setActiveTab("Assesment")}
        >
          Assesment
        </div>
      </div>
      {activeTab === "details" && (
        <div className="tab-content details-content">Details Content</div>
      )}
      {activeTab === "Assesment" && (
        <div className="tab-content details-content">
          <div className="assessment-content">
            <SYLListing apiCall={apiCall} setApiCall={SetApiCall} />
          </div>
          {/* <hr/> */}
          <div className="assessment-content ">
            <VivaList apiCall={apiCall} setApiCall={SetApiCall} id={id}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDetails;
