import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch } from "react-redux";
import { setRescheduleDate } from "../../features/tickets/ticketsSlice";

function CalendarReact() {
  const [value, setValue] = useState(new Date());
  const dispatch = useDispatch();
  dispatch(setRescheduleDate(value.toLocaleString()));

  const disableDates = ({ date }) => {
    const today = new Date();

    // Disable past dates
    if (date < today.setHours(0, 0, 0, 0)) {
      return true;
    }

    // Disable Saturdays (6) and Sundays (0)
    const day = date.getDay();
    if (day === 0 || day === 6) {
      return true;
    }

    return false;
  };

  function onChange(nextValue) {
    setValue(nextValue);
    dispatch(setRescheduleDate(nextValue.toLocaleString()));
  }

  return (
    <Calendar onChange={onChange} value={value} tileDisabled={disableDates} />
  );
}

export default CalendarReact;
