import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activities: [],
  isActivityEditable : false,
  isFieldsDisabled : false,
  isAddNewActivity : false
};

const activityMasterSlice = createSlice({
  name: "activityMaster",
  initialState,
  reducers: {
    setMasterActivities: (state, action) => {
      state.activities = action.payload;
    },
    setActivityEditable : (state, action) => {
      state.isActivityEditable = action.payload
    },
    setFieldsDisable : (state, action) => {
      state.isFieldsDisabled = action.payload
    },
    setAddNewActivity : (state, action) => {
      state.isAddNewActivity = action.payload
    }
  },
});

export const { setMasterActivities, setActivityEditable, setFieldsDisable, setAddNewActivity } = activityMasterSlice.actions;

export default activityMasterSlice.reducer;
