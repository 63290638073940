// src/features/activity/activityList.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card";
import Table from "../../../../components/Table/Table";
// import "./ticketsList.scss";
import { closeModal, openModal } from "../../../../components/Modal/ModalSlice";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button";
import {
  assessmentStatusUpdate,
  cancelViva,
  getViva,
} from "../courseDetailsService";
import { BanLogo, CheckLogo, CloseLogo } from "../../../../assets/images/svg";
import FormInput from "../../../../components/Form/FormInput";
import { RescheduleContentModal } from "../../../tickets/TicketsList";
import { setSelectedTicket } from "../../../tickets/ticketsSlice";
import "./vivaList.scss";
import DropdownButton from "../../../../components/DropdownButton";

const VivaList = ({ apiCall, setApiCall, id }) => {
  // const [callApi, setCallApi] = useState(true);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //   const ticketStatusList = ["ALL", "SCHEDULED", "COMPLETED", "CANCELLED"];
  const [vivaDetails, setVivaDetails] = useState([]);

  const tickets = useSelector((state) => state.tickets.tickets);
  const [selectedValues, setSelectedValues] = useState({});

  const handleChange = (event, row) => {
    const value = event.target.value;
    console.log(row);
    dispatch(setSelectedTicket(row));

    // Update the selected value for the specific row
    setSelectedValues({
      ...selectedValues,
      [row.id]: value,
    });

    if (value === "join") {
      // Open the meeting link in a new tab
      window.open(row.meeting_link, "_blank");
    } else {
      // Show modal confirmation for other actions
      dispatch(openModal(`${value}_modal`));
    }
  };

  useEffect(() => {
    const fetchVivaDetais = async () => {
      const data = await getViva(id);
      setVivaDetails(data);
    };

    fetchVivaDetais();
  }, [apiCall]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      width: "300px",
    },
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: true,
      width: "300px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => {
        return row.status === "scheduled" ? (

          <DropdownButton
            dropDownBtnOptions={[
              {
                label: "Join",
                onClick: () => {
                  dispatch(setSelectedTicket(row));
                  window.open(
                    row.meeting_link,
                    "_blank",
                    "noopener,noreferrer"
                  );
                },
              },
              {
                label: "Reschedule",
                onClick: () => {
                  dispatch(setSelectedTicket(row));
                  dispatch(openModal("reschedule_modal"));
                },
              },
              {
                label: "Cancel",
                onClick: () => {
                  dispatch(setSelectedTicket(row));
                  dispatch(openModal("cancel_modal"));
                },
              },
            ]}
          />
        ) : null;
      },
      width: "200px",
    },
    {
      id: "approve",
      name: "Approve ?",
      cell: (row) => (
        <div className={`d-flex justify-content-start`}>
          {row?.status && row?.status.toUpperCase() === "SCHEDULED" ? (
            <>
              <button
                className="btn"
                onClick={() => {
                  dispatch(openModal("stage_approval_modal_viva"));
                  console.log("approve button clicked");
                }}
              >
                <CheckLogo />
              </button>

              <button
                className="btn"
                onClick={() => {
                  dispatch(openModal("stage_rejection_modal_viva"));
                }}
              >
                <CloseLogo />
              </button>
            </>
          ) : (
            <button className="btn">
              <BanLogo />
            </button>
          )}
        </div>
      ),
      width: "120px",
    },
  ];

  const onRowClick = (row) => {
    dispatch(setSelectedTicket(row));
  };

  return (
    <div className="tickets-page viva-listing m-3">
      {/* <Card> */}
      {/* <TableComponent data={activities} />
       */}
      <div className="title p-3 border-black border-bottom fw-bold">Viva</div>
      <Table
        entity="tickets"
        data={vivaDetails}
        columns={columns}
        //   statusList={ticketStatusList}
        hasAddBtn={false}
        onRowClick={onRowClick}
        clickableRow={false}
      />

      <TicketListingModal callApi={apiCall} setApiCall={setApiCall} />
      {/* </Card> */}
      <VivaListingModal
        setApiCall={setApiCall}
        id={id}
        vivaDetails={vivaDetails}
      />
    </div>
  );
};

export default VivaList;

const TicketListingModal = ({ callApi, setApiCall }) => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalType);
  const onReschedule = () => {
    dispatch(closeModal());
    dispatch(openModal("reschedule_final_modal"));
  };

  const selectedTicket = useSelector((state) => state.tickets.selectedTicket);

  const onCancel = async () => {
    const body = {
      calendar_type: "schedule_viva",
      course_assessment_submission_id: selectedTicket.id,
    };
    const data = await cancelViva(body);
    dispatch(closeModal());
    setApiCall(!callApi);
  };
  switch (modalType) {
    case "reschedule_modal":
      return <ConfirmationModal title="Reschedule" onSubmit={onReschedule} />;
    case "cancel_modal":
      return <ConfirmationModal title="Cancel" onSubmit={onCancel} />;
    case "reschedule_final_modal":
      return (
        <RescheduleContentModal
          title="Reschedule"
          calendarType="schedule_viva"
          fromDetails={false}
          setCallApi={setApiCall}
          callApi={callApi}
        />
      );
    default:
      return null;
  }
};

const ConfirmationModal = ({ title, onSubmit }) => {
  const dispatch = useDispatch();
  return (
    <Modal title={title} className="modal-content2-sm">
      <div>
        <h5>Are you sure you want to {title.toLowerCase()}?</h5>
        <p>Please click on “Confirm” to continue.</p>
      </div>
      <div className="d-flex justify-content-end gap-3 mt-3 mb-3">
        <Button
          label="Confirm"
          onClick={() => {
            dispatch(closeModal());
            onSubmit && onSubmit();
          }}
          backgroundColor={"#EA983E"}
          textColor={"white"}
        />
      </div>
    </Modal>
  );
};

const VivaListingModal = ({ setApiCall, id, vivaDetails }) => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalType);
  // console.log(modalType);

  const handleVivaApproveReject = async (status, remark) => {
    const data = await assessmentStatusUpdate(
      localStorage.getItem("token"),
      vivaDetails[0].course_assessment_submission_id,
      status,
      id,
      remark
    );
    alert(data.message);
    setApiCall(new Date());
    dispatch(closeModal());
  };

  switch (modalType) {
    case "stage_approval_modal_viva":
      return (
        <ApprovalRejectionModalContent
          title="Approve Viva"
          btnLabel="Approve"
          onSubmit={handleVivaApproveReject}
          status="approve"
          isStage={true}
        />
      );
    case "stage_rejection_modal_viva":
      return (
        <ApprovalRejectionModalContent
          title="Reject Viva"
          btnLabel="Reject"
          onSubmit={handleVivaApproveReject}
          status="reject"
          isStage={true}
        />
      );
    default:
      return null;
  }
};

const ApprovalRejectionModalContent = ({
  title,
  btnLabel,
  onSubmit,
  status,
  isStage = false,
}) => {
  const toShowRemarkField = true;
  console.log("Modal is called");
  const dispatch = useDispatch();

  const [remark, setRemark] = useState("");
  console.log(remark);

  return (
    <Modal title={title}>
      <div className="d-flex h-100 flex-column">
        <h6>Are you sure you want to {title}? </h6>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit && onSubmit(status, remark);
          }}
        >
          {toShowRemarkField && (
            <FormInput
              name="comment"
              label="Comments"
              type="textarea"
              placeholder="Any comments/remarks..."
              rows={5}
              value={remark}
              className="my-3"
              onChange={(e) => setRemark(e.target.value)}
            />
          )}
          <div className="d-flex justify-content-end gap-3 mt-3 mb-3">
            <Button
              label={btnLabel}
              backgroundColor={"#f6921e"}
              textColor={"#fff"}
              type="submit"
            />
            <Button
              label={"Cancel"}
              onClick={() => {
                console.log("close modal");
                dispatch(closeModal());
              }}
              backgroundColor={"#fff"}
              textColor={"#000"}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
