import { useState } from "react";
import Card from "../../../components/Card";
import { useSelector } from "react-redux";
import {
  FormInputRenderer,
  separator,
} from "../../master/course/AddCourse/detail/CourseMasterDetail";
import Button from "../../../components/Button";
import { TicketListingModal } from "../TicketsList";
import { useDispatch } from "react-redux";
import { openModal } from "../../../components/Modal/ModalSlice";

const TicketDetails = () => {
  const allTickets = useSelector((state) => state.tickets.tickets);
  const selectedTicket = useSelector((state) => state.tickets.selectedTicket);

  const isEditable = useSelector((state) => state.tickets.isTicketEditable);
  const isEditEntry = useSelector((state) => state.tickets.isEditEntry);
  const isNewEntry = useSelector((state) => state.tickets.isNewEntry);

  const [values, setValues] = useState({
    course_id: selectedTicket?.id ?? "",
    member_name: selectedTicket?.name ?? "",
    purpose: selectedTicket?.purpose ?? "",
    date_time: selectedTicket?.start_time ?? "",
    description: selectedTicket?.description ?? "",
    status: selectedTicket?.status ?? "",
    duration: selectedTicket?.duration ?? "30 mins",
    meeting_link: selectedTicket?.meeting_link ?? "",
    assigned_to: selectedTicket?.assigned_to ?? "",
    member_email: selectedTicket?.email ?? "",
    member_phone: selectedTicket?.phone ?? "",
  });

  const dispatch = useDispatch();
  const inputs = [
    {
      id: 1,
      name: "course_id",
      type: "text",
      label: "Id",
      disabled: true,
    },
    {
      id: 2,
      name: "member_name",
      type: "text",
      label: "Member Name",
      disabled: true,
    },
    {
      id: 3,
      name: "purpose",
      type: "text",
      label: "Purpose",
      disabled: true,
    },
    {
      id: 4,
      name: "date_time",
      type: "text",
      label: "Date & Time",
      disabled: true,
    },
    {
      id: 5,
      name: "description",
      type: "textarea",
      label: "Description",
      disabled: true,
    },
    {
      id: 6,
      name: "status",
      type: "text",
      label: "Status",
      disabled: true,
    },
  ];

  const additionalInputs = [
    {
      id: 7,
      name: "duration",
      type: "text",
      label: "Duration",
      disabled: true,
    },
    {
      id: 8,
      name: "meeting_link",
      type: "text",
      label: "Call Link",
      disabled: true,
    },
    {
      id: 9,
      name: "assigned_to",
      type: "text",
      label: "Assigned To",
      disabled: true,
    },
    {
      id: 10,
      name: "member_email",
      type: "text",
      label: "Member Email",
      disabled: true,
    },
    {
      id: 11,
      name: "member_phone",
      type: "text",
      label: "Member Phone",
      disabled: true,
    },
  ];

  const onChange = (e) => {
    if (e.target.type === "file") {
      setValues({
        ...values,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setValues({
        ...values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openModal("reschedule_modal"));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(openModal("cancel_modal"));
  };

  return (
    <Card>
      <div className="p-3 ">
        <form onSubmit={handleSubmit} className="row">
          {FormInputRenderer(inputs, values, onChange)}
          {separator("Additional Details")}
          {FormInputRenderer(additionalInputs, values, onChange)}

          {(selectedTicket?.status === "scheduled" ||
            selectedTicket?.status === "rescheduled") && (
            <div className="d-flex flex-column flex-md-row mt-5 justify-content-end gap-3">
              <Button
                label={"Cancel"}
                backgroundColor={"#fff"}
                textColor={"#f6921e"}
                className="cancel btn"
                onClick={handleCancel}
                type="button"
              />
              <Button
                label={"Reschedule"}
                backgroundColor={"#f6921e"}
                textColor={"#fff"}
                className=""
                type="submit"
              />
            </div>
          )}
        </form>
      </div>
      <TicketListingModal fromDetails={true} />
    </Card>
  );
};

export default TicketDetails;
