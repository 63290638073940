export function formatToIST(dateString) {
  const date = new Date(dateString);

  // Extract parts excluding the year
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata", // Convert to IST
  };

  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const formattedDate = formatter.format(date);

  return formattedDate;
}

export const formatDateString = (inputDate) => {
  const date = new Date(inputDate);

  // Array of day names
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get components of the date (in GMT)
  const dayName = days[date.getUTCDay()]; // Get day name
  const day = date.getUTCDate(); // Get day of the month
  const month = months[date.getUTCMonth()]; // Get month name
  const year = date.getUTCFullYear(); // Get year
  const hours = date.getUTCHours(); // Get hours in UTC
  const minutes = date.getUTCMinutes(); // Get minutes in UTC

  // Determine AM/PM and format the hour
  const amPm = hours >= 12 ? "pm" : "am";
  const formattedHour = hours % 12 || 12;

  // Format day with suffix (st, nd, rd, th)
  const daySuffix = (day) => {
    if (day % 10 === 1 && day !== 11) return "st";
    if (day % 10 === 2 && day !== 12) return "nd";
    if (day % 10 === 3 && day !== 13) return "rd";
    return "th";
  };

  // Format the final string
  return `${dayName} ${day}${daySuffix(
    day
  )} ${month}, ${formattedHour}:${minutes.toString().padStart(2, "0")}${amPm}`;
};
