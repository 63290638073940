import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { setActivityEditable } from "../activity/activityMasterSlice";
import { useNavigate } from "react-router-dom";
import { EditLogo } from "../../../assets/images/svg";
import Card from "../../../components/Card";
import Table from "../../../components/Table/Table";
import { getCourseMaster } from "./courseMasterService";
import { useState } from "react";
import {
  setCoursesMaster,
  setIsCourseEditable,
  setIsEditEntry,
  setIsNewEntry,
} from "./courseMasterSlice";

function CourseMasterList() {
  const [courses, setCourses] = useState([]);
  const [callApi, setCallApi] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseMaster = async () => {
      try {
        const response = await getCourseMaster();
        setCourses(response);
        dispatch(setCoursesMaster(response));
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseMaster();
  }, [callApi]);

  const onAddclick = () => {
    dispatch(setIsCourseEditable(true));
    dispatch(setIsNewEntry(true));
    dispatch(setIsEditEntry(false));
    navigate("add");
  };
  const courseStatusList = ["ALL", "Published", "Unpublished"];
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${row.id}`);
          }}
        >
          {row.name}
        </span>
      ),
      width: "42%",
    },
    {
      name: "Organization",
      selector: (row) => row.school_name,
      sortable: true,
      width: "122px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      width: "122px",
    },

    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      width: "122px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      width: "122px",
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            dispatch(setIsCourseEditable(true));
            dispatch(setIsEditEntry(true));
            dispatch(setIsNewEntry(false));
            navigate(`${row.id}`);
          }}
        >
          <EditLogo />
        </button>
      ),
    },
  ];

  const onRowClick = (row) => {
    dispatch(setIsCourseEditable(false));
    dispatch(setIsEditEntry(false));
    dispatch(setIsNewEntry(false));
  };

  return (
    <div className="course__master--page">
      <Card>
        <div className="course__master--list p-3 pt-2">
          <Table
            entity="course-master"
            data={courses}
            columns={columns}
            statusList={courseStatusList}
            onAddClick={onAddclick}
            buttonLabel="+ Course"
            onRowClick={onRowClick}
          />
        </div>
      </Card>
    </div>
  );
}

export default CourseMasterList;
