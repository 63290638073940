export const useTimeMinMax = (selectedDate) => {
  const formatCustomDate = (inputDate, time) => {
    console.log(inputDate);
    console.log(time);
    // Split the date and time
    const [datePart] = inputDate.split(", ");
    const [day, month, year] = datePart.split("/");
    console.log(datePart);
    console.log(day, month, year);

    // Construct the formatted date string manually
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}T${time}`;

    return formattedDate;
  };

  const timeMin = formatCustomDate(selectedDate, "09:00:00+05:30");
  const timeMax = formatCustomDate(selectedDate, "17:00:00+05:30");

  return { timeMin, timeMax };
};
