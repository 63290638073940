import React, { useEffect, useState } from "react";
import { curriculumDetails } from "./curricumListService";
import { useDispatch, useSelector } from "react-redux";
import { setCurriculum, setEditMode } from "./curriculumSlice";
import Table from "../../../components/Table/Table";
import Card from "../../../components/Card";
import { EditLogo } from "../../../assets/images/svg";
import { useNavigate } from "react-router-dom";
import AddCurriculumModal from "./AddCurriculumModal";
import Alert from "../../../components/Alerts/Alert";

const CurriculumList = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMesage] = useState("");
  const [apiCall, setApiCall] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCurriculumList = async () => {
      const data = await curriculumDetails();
      dispatch(setCurriculum(data));
    };

    fetchCurriculumList();
    setApiCall(false);
  }, [dispatch, apiCall]);

  const curriculums = useSelector((state) => state.curriculumList.curriculums);
  console.log(curriculums);

  const curriculumStatusList = ["ALL", "ACTIVE", "INACTIVE"];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "25%",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      width: "25%",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
      // width: "22.5%",
      maxWidth: "160px",
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <button
          className="btn"
          onClick={() => {
            dispatch(setEditMode(true));
            navigate(`${row.id}`);
          }}
        >
          <EditLogo />
        </button>
      ),
    },
  ];

  return (
    <div className="curriculum-list">
      {showModal && (
        <AddCurriculumModal
          setShowModal={setShowModal}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          alertMessage={alertMessage}
          setAlertMesage={setAlertMesage}
          setApiCall={setApiCall}
        />
      )}
      {showAlert && (
        <Alert
          message={alertMessage}
          type="success"
          onClose={() => setShowAlert(false)}
        />
      )}
      <Card>
        <Table
          entity="curriculum-master"
          data={curriculums}
          columns={columns}
          statusList={curriculumStatusList}
          onAddClick={() => setShowModal(true)}
        />
      </Card>
    </div>
  );
};

export default CurriculumList;
