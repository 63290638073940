import { createSlice } from "@reduxjs/toolkit";

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    tickets: [],
    selectedTicket: null,
    isTicketEditable: false,
    isNewEntry: false,
    isEditEntry: false,
    rescheduleDate: null,
  },
  reducers: {
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setSelectedTicket: (state, action) => {
      state.selectedTicket = action.payload;
    },
    setIsTicketEditable: (state, action) => {
      state.isTicketEditable = action.payload;
    },
    setIsNewEntry: (state, action) => {
      state.isNewEntry = action.payload;
    },
    setIsEditEntry: (state, action) => {
      state.isEditEntry = action.payload;
    },
    setRescheduleDate: (state, action) => {
      state.rescheduleDate = action.payload;
    },
  },
});

export const {
  setTickets,
  setSelectedTicket,
  setIsTicketEditable,
  setIsNewEntry,
  setIsEditEntry,
  setRescheduleDate,
} = ticketsSlice.actions;
export default ticketsSlice.reducer;
