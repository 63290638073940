import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../features/login/loginSlice";
import activityReducer from "../features/activity/activitySlice";
import activityMediaReducer from "../features/activity/activityDetails/activityDetailsSlice";
import courseReducer from "../features/course/courseSLice";
import assessmentDetailReducer from "../features/course/courseDetails/courseDetailsSlice";
import activityMasterReducer from "../features/master/activity/activityMasterSlice";
import curriculumListReducer from "../features/master/curriculum/curriculumSlice";
import schoolMasterReducer from "../features/schools/school/schoolMasterSlice";
import studentMasterReducer from "../features/schools/student/studentMasterSlice";
import staffMasterReducer from "../features/schools/staff/staffMasterSlice";
import modalReducer from "../components/Modal/ModalSlice";
import { staffMaster, studentMaster, tickets } from "../utils/constants/routes";
import courseMasterReducer from "../features/master/course/courseMasterSlice";
import breadCrumbReducer from "../Layout/SubHeader/breadCrumbSlice";
import ticketsReducer from "../features/tickets/ticketsSlice";

const store = configureStore({
  reducer: {
    login: loginReducer,
    activity: activityReducer,
    activityDetails: activityMediaReducer,
    course: courseReducer,
    assessmentDetail: assessmentDetailReducer,
    activityMaster: activityMasterReducer,
    curriculumList: curriculumListReducer,
    schoolMaster: schoolMasterReducer,
    modal: modalReducer,
    studentMaster: studentMasterReducer,
    staffMaster: staffMasterReducer,
    courseMaster: courseMasterReducer,
    breadCrumb: breadCrumbReducer,
    tickets: ticketsReducer,
  },
});

export default store;
