import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";



function formatDate(dateStr) {
  const date = new Date(dateStr);
  const options = { weekday: 'long', day: 'numeric', month: 'long' };
  return date.toLocaleDateString('en-US', options);
}

function formatTime(startStr, endStr) {
  const start = new Date(startStr);
  const end = new Date(endStr);

  const startHours = start.getHours();
  const startMinutes = String(start.getMinutes()).padStart(2, '0');
  const endHours = end.getHours();
  const endMinutes = String(end.getMinutes()).padStart(2, '0');

  return `${startHours}:${startMinutes} to ${endHours}:${endMinutes}`;
}


export const assessmentDetails = async (token, id) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/assessment/details`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_member_id: id,
      },
    }
  );


  return response.data.data;
};

//V2 assessment details supporting multiple uploads
export const assessmentDetailsV2 = async (token, course_member_id) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/assessment/multiple/details`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          course_member_id: course_member_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: `Failed to fetch data from server ${error.message}`,
    };
  }
};

export const assessmentDetailsSYLQuestions = async (
  token,
  course_member_id
) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/assessment/syl/questions`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          course_member_id: course_member_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: `Failed to fetch data from the server ${error.message}`,
    };
  }
};

export const assessmentStatusUpdate = async (
  token,
  id,
  status,
  user_id,
  remark
) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/assessment/status/update`,
    {
      remark: remark,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_assessment_submission_id: id,
        status: status,
        course_member_id: user_id,
      },
    }
  );
  return response.data;
};

export const assessmentFileStatusUpdate = async (
  submission_details_id,
  status,
  remark
) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/assessment/details/status/update`,
      { remark },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          submission_detail_id: submission_details_id,
          status: status,
        },
      }
    );

    if (response.data.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: `Failed to fetch data from server ${error.message}`,
    };
  }
};

export const vivaSchedule = async (
  token,
  id,
  startDate,
  endDate,
  mode,
  link
) => {

  const start = new Date(startDate);
  const end = new Date(endDate);


  const differenceInMilliseconds = end - start;

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60); // Convert ms to minutes

  let duration = `${differenceInMinutes} mins`;

  if (differenceInMinutes >= 60) {
    const hours = Math.floor(differenceInMinutes / 60); // Correctly calculate hours
    const minutes = differenceInMinutes % 60; // Remaining minutes after subtracting hours


    if (minutes === 0) {
      duration = `${hours} hrs`;
    } else {
      duration = `${hours} hrs ${minutes} mins`;
    }
  }

  const response = await axios.post(
    `${DEV_BASE_URL}/assessment/viva/schedule?course_assessment_submission_id=${id}`,
    {
      url: link,
      duration: duration,
      start_time: startDate,
      end_time: endDate,
      comment: "please join on time",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_assessment_submission_id: id,
      },
    }
  );

  return response.data;
};


export const getViva = async(course_member_id) => {
  const response = await axios.get(
    `${DEV_BASE_URL}/assessment/viva/details`,
    {
      headers : {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      params : {
        course_member_id : course_member_id
      }
    }
  )

  if (Object.keys(response.data.data).length){
    const apiData = response.data.data; // Access the 'data' field
  const comment = apiData.comment; // Access 'comment'

  // Check if 'comment' exists to prevent errors
  if (!comment) {
    throw new Error("Comment details are missing in the API response.");
  }

  // Use formatDate and formatTime (implement these functions earlier)
  const finalData = [];
  finalData.push({
    date: formatDate(comment.start_time),
    time: formatTime(comment.start_time, comment.end_time),
    status: apiData.status, // Using 'status' from 'data'
    course_assessment_submission_id : apiData.course_assessment_submission_id,
    meeting_link : comment.url,
    description : comment.comment,
    id : apiData.course_assessment_submission_id
  });

  return finalData;
  }
  else{
    return []
  }
  
}

export const rescheduleViva = async(body) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/calendar/update_event`,
    body,
    {
      headers : {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    }
  )
  return response.data
}

export const cancelViva = async(body) => {
  const response = await axios.post(
    `${DEV_BASE_URL}/api/calendar/delete_event`,
    body,
    {
      headers : {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    }
  )
  return response.data 
}