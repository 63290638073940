import { useEffect, useState } from "react";
import Card from "../../../components/Card";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setIsEditEntry,
  setIsNewEntry,
  setIsStaffEditable,
  setStaffs,
  setSelectedStaff,
} from "./staffMasterSlice";
import { closeModal, openModal } from "../../../components/Modal/ModalSlice";
import Modal from "../../../components/Modal/Modal";
import DynamicFormRenderer from "../../../components/Form/DynamicFormRenderer";
import { schoolMasterDetails } from "../school/schoolMasterService";
import {
  createStaffAPI,
  getStaffRoles,
  staffMasterDetails,
  updateStaffAPI,
} from "./staffMasterService";
import "./index.scss";
import { EditLogo } from "../../../assets/images/svg";

const List = () => {
  const [callApi, setCallApi] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStaffMaster = async () => {
      const data = await staffMasterDetails(page, rowsPerPage);
      // console.log(data.data.students);
      dispatch(setStaffs(data.data.staffs));
      setTotalRows(data.data.total_records);
    };

    fetchStaffMaster();
    setCallApi(true);
  }, [dispatch, token, callApi, page, rowsPerPage]);

  const staff = useSelector((state) => state.staffMaster.staffs);
  const isEditable = useSelector((state) => state.staffMaster.isStaffEditable);
  const isEditEntry = useSelector((state) => state.staffMaster.isEditEntry);
  const isNewEntry = useSelector((state) => state.staffMaster.isNewEntry);

  const onAddClick = () => {
    dispatch(setIsStaffEditable(true));
    dispatch(setIsNewEntry(true));
    dispatch(setIsEditEntry(false));
    dispatch(openModal("staffModal"));
  };

  const staffStatusList = ["ALL", "Active", "Inactive"];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "profile",
      selector: (row) => row.profile_url,
      cell: (row) => (
        <img
          src={row.profile_url}
          alt="profile"
          className="staff__master--profile-img my-1"
        />
      ),
      width: "7%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.name}
        >
          {row.name}
        </span>
      ),
      width: "12%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "25%",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile_number,
      sortable: true,
      width: "15% ",
    },
    {
      name: "School",
      selector: (row) => row.school_name,
      sortable: true,
      width: "12%",
    },

    {
      name: "Role",
      selector: (row) => row.role_name,

      width: "10%",
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="btn btn-outline"
          onClick={() => {
            dispatch(setIsStaffEditable(true));
            dispatch(setIsEditEntry(true));
            dispatch(setIsNewEntry(false));
            dispatch(setSelectedStaff(row));
            dispatch(openModal("staffModal"));
          }}
        >
          <EditLogo />
        </button>
      ),
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: false,
    //   cell: (row) => (
    //     <span className={`status ${row.status.toLowerCase()}`}>
    //       {row.status}
    //     </span>
    //   ),
    //   width: "100px",
    // },
    // {
    //   name: "",
    //   // button: true,
    //   cell: (row) => (
    //     <button
    //       className="btn"
    //       onClick={() => {
    //         dispatch(setSchoolEditable(true));
    //         dispatch(setIsEditEntry(true));
    //         dispatch(setIsNewEntry(false));
    //         navigate(`${row.id}`);
    //       }}
    //     >
    //       <EditLogo />
    //     </button>
    //   ),
    // },
  ];

  const onRowClick = (row) => {
    dispatch(setIsEditEntry(false));
    dispatch(setIsNewEntry(false));
  };

  return (
    <div className="staff__master--page">
      <Card>
        <div className="staff__master--list p-3 pt-2">
          <Table
            entity="staff-master"
            data={staff}
            columns={columns}
            statusList={staffStatusList}
            onAddClick={onAddClick}
            buttonLabel="+ Staff"
            onRowClick={onRowClick}
            isServerSidePagination
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            paginationTotalRows={totalRows}
            clickableRow={false}
          />
        </div>
        <StaffModal callApi={callApi} setCallApi={setCallApi} />
      </Card>
    </div>
  );
};

const StaffModal = ({ callApi, setCallApi }) => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalType);
  const [schoolDropdownOptions, setSchoolDropdownOptions] = useState([]);
  const [roleDropdownOptions, setRoleDropdownOptions] = useState([]);

  const token = localStorage.getItem("token");
  const isEditEntry = useSelector((state) => state.staffMaster.isEditEntry);
  const selectedStaff = useSelector((state) => state.staffMaster.selectedStaff);

  useEffect(() => {
    const fetchSchoolDropdownOptions = async () => {
      const data = await schoolMasterDetails(token);

      let d = [];
      data.map((item) => {
        d.push({
          value: item.id,
          label: item.name,
        });
      });
      setSchoolDropdownOptions(d);
    };

    const fetchRoleDropdownOptions = async () => {
      const data = await getStaffRoles();
      let d = [];
      data.status &&
        data.data.map((item) => {
          d.push({
            value: item.id,
            label: item.name,
          });
        });
      setRoleDropdownOptions(d);
    };

    fetchSchoolDropdownOptions();
    fetchRoleDropdownOptions();
  }, []);

  const formConfig = [
    {
      name: "id",
      label: "Id",
      type: "text",
      value: isEditEntry ? selectedStaff?.id : "",
      disabled: true,
    },
    {
      name: "school_id",
      label: "School",
      type: "select",
      options: schoolDropdownOptions,
      value: isEditEntry ? selectedStaff?.school_id : "",
      required: true,
      disabled: isEditEntry,
    },
    {
      name: "first_name",
      label: "First Name",
      type: "text",
      value: isEditEntry ? selectedStaff?.first_name : "",
      required: true,
    },
    {
      name: "last_name",
      label: "Last Name",
      type: "text",
      required: true,
      value: isEditEntry ? selectedStaff?.last_name : "",
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      required: true,
      pattern: "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$",
      value: isEditEntry ? selectedStaff?.email : "",
    },
    {
      name: "mobile_number",
      label: "Mobile Number",
      type: "text",
      required: true,
      pattern: "^[0-9]{10}$",
      value: isEditEntry ? selectedStaff?.mobile_number : "",
    },
    {
      name: "file",
      label: "Profile Photo",
      type: "file",
      required: true,
      value: isEditEntry ? selectedStaff?.profile_url : "",
    },
    {
      name: "role_id",
      label: "Role",
      type: "select",
      options: roleDropdownOptions,
      value: isEditEntry
        ? roleDropdownOptions.find(
            (item) => item.label === selectedStaff?.role_name
          ).value
        : "",
      required: true,
    },
  ];

  const resetSlice = () => {
    dispatch(setIsStaffEditable(false));
    dispatch(setIsEditEntry(false));
    dispatch(setIsNewEntry(false));
  };

  const handleFormSubmit = async (data) => {
    // console.log("form submit", data);
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("mobile_number", data.mobile_number);
    formData.append("school_id", Number(data.school_id));
    formData.append("role_id", Number(data.role_id));

    //log the formData
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    if (isEditEntry) {
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
    }

    const response = isEditEntry
      ? await updateStaffAPI(selectedStaff.id, formData)
      : await createStaffAPI(formData);

    if (response.status) {
      alert(response.message);
      callApi ? setCallApi(false) : setCallApi(true);
      resetSlice();
      dispatch(closeModal());
    } else {
      alert(response.message);
      callApi ? setCallApi(false) : setCallApi(true);
      resetSlice();
      dispatch(closeModal());
    }
  };

  const onCloseClick = () => {
    resetSlice();
  };

  if (modalType === "staffModal") {
    return (
      <Modal
        title={isEditEntry ? "Edit Staff" : "Add Staff"}
        onCloseClick={onCloseClick}
      >
        <div className="d-flex h-100 flex-column">
          <DynamicFormRenderer
            formConfig={formConfig}
            hasCancelBtn={false}
            submitBtnText={isEditEntry ? "Update" : "Add"}
            handleSubmit={handleFormSubmit}
          />
        </div>
      </Modal>
    );
  }
};

export default List;
