import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTimeMinMax } from "./timeMinMax";
import { useEffect, useState } from "react";
import {
  getFreeBusySlots,
  rescheduleAppointment,
} from "../../features/tickets/ticketsService";
import Button from "../Button";
import { closeModal } from "../Modal/ModalSlice";
import { rescheduleViva } from "../../features/course/courseDetails/courseDetailsService";

const TimeSlots = ({
  rescheduleDescription,
  calendarType,
  fromDetails,
  setCallApi,
  callApi,
}) => {
  const [slots, setSlots] = useState([]);
  const dispatch = useDispatch();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const selectedDate = useSelector((state) => state.tickets.rescheduleDate);
  const selectedTicket = useSelector((state) => state.tickets.selectedTicket);

  const { timeMin, timeMax } = useTimeMinMax(selectedDate);

  const [datePart] = selectedDate.split(", ");
  const [day, month, year] = datePart.split("/");
  // Create a Date object
  const dateObj = new Date(`${year}-${month}-${day}`);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayName = days[dateObj.getDay()];

  useEffect(() => {
    const getSlots = async () => {
      const response = await getFreeBusySlots(timeMin, timeMax, calendarType);
      setSlots(response.data);
    };

    getSlots();
  }, [selectedDate]);

  const onSubmit = async () => {
    if (!selectedSlot) {
      alert("Please select a slot");
      return;
    }
    if (calendarType === "book_call") {
      const rescheduleAPIBody = {
        appointment_id: selectedTicket.id,
        event_id: selectedTicket.external_event_id,
        start_time: selectedSlot.start,
        end_time: selectedSlot.end,
        description: rescheduleDescription,
        calendar_type: calendarType,
      };
      setIsLoading(true);
      const data = await rescheduleAppointment(rescheduleAPIBody);
  
      if (data.status) {
        setIsLoading(false);
        alert(`Appointment ${data.data.status}`);
        dispatch(closeModal());
        !fromDetails ? setCallApi(!callApi) : window.location.replace("/tickets");
      }
    }
    else{
      const rescheduleAPIBody = {
      "course_assessment_submission_id" : selectedTicket.course_assessment_submission_id,
      "calendar_type" : calendarType,
      "start_time": selectedSlot.start,
      "end_time": selectedSlot.end,
      "description": rescheduleDescription,
    }
    setIsLoading(true);
    const data = await rescheduleViva(rescheduleAPIBody);
      if (data.status){
        setIsLoading(false);
        alert(data.message)
        setCallApi(!callApi)
        dispatch(closeModal());
        // setCallApi(!callApi)

      }
  }
  };

  return (
    <div
      className="d-flex w-100 flex-column justify-content-start slots-container align-items-center"
      style={{ marginTop: "-4rem" }}
    >
      <div
        className="badge bg-primary  rounded-circle d-flex flex-column align-items-center justify-content-center"
        style={{ width: "50px", height: "50px" }}
      >
        {dayName}
        <div className="">{day}</div>
      </div>
      <div className="slots row mt-3">
        {!isLoading && slots.length > 0 ? (
          slots.map((slot, index) => (
            <div className="col-4 my-2" key={index}>
              <button
                className={`w-100 btn ${
                  selectedSlot === slot ? "btn-primary" : "btn-outline-primary"
                } btn-lg`}
                key={index}
                onClick={() => setSelectedSlot(slot)}
              >
                {slot.start.slice(11, 16)}
              </button>
            </div>
          ))
        ) : (
          <p>No slots available</p>
        )}
        {isLoading && (
          <div
            className="d-flex justify-content-center align-items-center"
            // style={{ height: "100vh" }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="col-12 mt-4 ">
          <Button
            label="Reschedule"
            className="w-100"
            onClick={() => onSubmit()}
            backgroundColor={"#EA983E"}
            textColor={"white"}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};



export default TimeSlots;
