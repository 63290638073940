// Modal.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "./ModalSlice.js";
import "./modal.scss";

const Modal = ({ title, children, onCloseClick = () => {}, className }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modal); // Access modal state

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        onCloseClick();
        dispatch(closeModal());
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [isOpen, dispatch, onCloseClick]);

  if (!isOpen) return null; // Don't render if modal is closed

  return (
    <div
      className="modal-backdrop2"
      onClick={() => {
        onCloseClick();
        dispatch(closeModal());
      }}
    >
      <div
        className={`modal-content2 ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header2">
          <h4 className="mb-0">{title}</h4>
          <button
            onClick={() => {
              onCloseClick();
              dispatch(closeModal());
            }}
            className="close-button"
          >
            &times;
          </button>
        </div>
        <div className="modal-body2">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
