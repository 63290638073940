import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../../../components/Card";
import DynamicFormRenderer from "../../../../../components/Form/DynamicFormRenderer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import Alert from "../../../../../components/Alerts/Alert";
import {
  addActivity,
  fetchSingleActivity,
  getCurriculumDropdown,
  getGradeDropdown,
  updateActivity,
} from "../../activityMasterService";

import "./activityDetail.scss";
import { setActivityEditable, setAddNewActivity } from "../../activityMasterSlice";
import FormInput from "../../../../../components/Form/FormInput";
import { getCourseMaster } from "../../../course/courseMasterService";

const ActivityDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditable = useSelector(
    (state) => state.activityMaster.isActivityEditable
  );
  const isDisabled = useSelector(
    (state) => state.activityMaster.isFieldsDisabled
  )

  const isNewActivity = useSelector(
    (state) => state.activityMaster.isAddNewActivity
  )

  const [currentActivity, setCurrentActivity] = useState({}); // Initialize with an empty object
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [curriculumDropdown, setCurriculumDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [courses, setCourses] = useState([]);
  const [curriculumDropdownValue, setCurriculumDropdownValue] = useState("");
  const [showBtn , setShowBtn] = useState(false)

  const dispatch = useDispatch();

  // A change might be needed here.
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  const coursesOptions = ["Friction", "Throw the ball"];



  useEffect(() => {
    const fetchApis = async () => {
      if (id != "add") {
        const act = await fetchSingleActivity(id);
        setCurrentActivity(act);
        const curr = await getCurriculumDropdown(id);
        setCurriculumDropdown(curr);
        setCurriculumDropdownValue(act.curriculum_id);
      } else {
        const curr = await getCurriculumDropdown(id);
        setCurriculumDropdown(curr);
      }
    };

    fetchApis();

    return () => {
      // This function is called when the component unmounts
      dispatch(setActivityEditable(false));
      dispatch(setAddNewActivity(false))
    };
  }, [id]);

  useEffect(() => {
    const fetchCourseMaster = async () => {
      try {
        const response = await getCourseMaster();
        let d = [];
        response.forEach((element) => {
          d.push({
            value: element.id,
            label: element.name,
          });
        });
        console.log("setting courses................")
        setCourses(d);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCourseMaster();
  }, [id]);

  const onHandleSave = async (e) => {
    e.preventDefault()
    setShowBtn(true)
    const data = await addActivity(currentActivity);
    alert(data.message)
    setShowBtn(false);
    navigate("/activity-master");
  };

  const onHandleEdit = async (e) => {
    e.preventDefault()
    setShowBtn(true)
    const data = await updateActivity(currentActivity);
    alert(data.message)
    setShowBtn(false)
    navigate("/activity-master");
  };

  const getCourseMasterValues = (course_id) => {
    let course = courses.find((c) => c.value == course_id);
    console.log(course)
    console.log(course)
    return course ? course.label : "";
  }

  let gradeDrp = async (id) => {
    let grades = await getGradeDropdown(id);
    setGradeDropdown(grades);
  };

  useEffect(() => {
    if (curriculumDropdownValue || isEditable) {
      gradeDrp(curriculumDropdownValue);
    }
  }, [curriculumDropdownValue]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    // if (name == "courses") {
      console.log(currentActivity)
    // }
    
    if (name == "curriculum_id") {
      setCurriculumDropdownValue(value);
      gradeDrp(value);
    }
    setCurrentActivity((prev) => ({
      ...prev,
      [name]: files ? files[0] : value, // Dynamically update the correct field
    }));
  };

  const masterActivityDetailFormConfig = [
    {
      id: "activityId",
      type: "text",
      label: "Id",
      name: "id",
      value: isEditable ? currentActivity?.id || "" : "",
      placeholder: "Enter Id",
      required: true,
      disabled: true,
    },
    {
      id: "activityMasterName",
      type: "text",
      label: "Name",
      name: "name",
      value: isEditable ? currentActivity?.name || "" : "",
      placeholder: "Enter Name",
      onChange: isEditable ? handleInputChange : null,
      required: true,
      disabled: isDisabled,
      errorMessage: "Activity Name is required",
    },
    {
      id: "curriculum_id",
      name: "curriculum_id",
      type: "select",
      label: "Curriculum",
      placeholder: "Curriculum",
      options: curriculumDropdown,
      value: isEditable ? curriculumDropdownValue || "" : "",
      onChange: isEditable ? handleInputChange : null,
      required: true,
      disabled: isDisabled,
    },
    {
      id: "grade_id",
      name: "grade_id",
      type: "select",
      label: "Grade",
      placeholder: "Grade",
      options: gradeDropdown,
      value: isEditable ? currentActivity?.grade_id || "" : "",
      onChange: isEditable ? handleInputChange : null,
      required: true,
      disabled: isDisabled,
    },
    {
      id: "formFile",
      type: "file",
      label: "Highlight Image",
      name: "highlight_img",
      value: isEditable ? currentActivity?.highlight_img || "" : "",
      placeholder: "Enter Highlight Image",
      onChange: isEditable ? handleInputChange : null,
      required: false,
      disabled: isDisabled,
    },
    {
      id: "highlightVideo",
      name: "highlight_vid",
      type: "file",
      label: "Highlight Video",
      value: isEditable ? currentActivity?.highlight_vid || "" : "",
      placeholder: "Enter Highlight Video",
      onChange: isEditable ? handleInputChange : null,
      required: false,
      disabled: isDisabled,
    },
    {
      id: "curriculumDescription",
      name: "description",
      type: "textarea",
      label: "Activity Note",
      value: isEditable ? currentActivity?.description || "" : "",
      placeholder: "Enter Description",
      onChange: isEditable ? handleInputChange : null,
      required: false,
      disabled: isDisabled,
    },
    {
      id: "status",
      name: "status",
      type: "select",
      label: "Status",
      value: isEditable ? currentActivity?.status || "" : "",
      placeholder: "Status",
      options: statusOptions,
      onChange: isEditable ? handleInputChange : null,
      required: true,
      disabled: isDisabled,
    },
    {
      id: "courses",
      name: "courses",
      type: "select",
      label: "Courses",
      value: isEditable ? currentActivity?.courses || "" : "",
      placeholder: "Courses",
      options: courses,
      onChange: isEditable ? handleInputChange : null,
      required: true,
      disabled: isDisabled,
    },
  ];

  return (
    <div className="activity-details-form">
      {showAlert && (
        <Alert
          message={alertMessage}
          type="success"
          onClose={() => setShowAlert(false)}
        />
      )}
      <div className="add-curriculum-form">
        <form onSubmit={(e) => (!isNewActivity ? onHandleEdit(e) : onHandleSave(e))}>
          <div className="row px-2">
            {masterActivityDetailFormConfig.map((field, idx) => (
              <FormInput key={idx} {...field} className="col-md-6" />
            ))}
          </div>
          <div className="activity-save-btn">
            {isEditable && (
              <Button
                label="Save"
                backgroundColor="#f6921e"
                textColor="white"
                type="submit"
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActivityDetail;
