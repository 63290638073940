import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./courseSyl.scss";
import Card from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCourseSyl,
  fetchQuestions,
  updateCourseSyl,
} from "../../courseMasterService";
import axios from "axios";
import { useSelector } from "react-redux";

const AssessmentForm = () => {
  const [assessments, setAssessments] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const isNewEntry = useSelector((state) => state.courseMaster.isNewEntry); // Replace `someReducer` with the correct slice name.

  // Fetch questions if editing
  useEffect(() => {
    const fetchData = async () => {
      if (!isNewEntry) {
        console.log("id", id);
        const prefilledQuestions = await fetchQuestions(id);
        console.log(prefilledQuestions);
        setAssessments(prefilledQuestions || []);
      } else {
        // For new entry, initialize default assessments
        setAssessments([
          { question: "This is dummy question 1?", mandatory: "No" },
          { question: "This is dummy question 2?", mandatory: "Yes" },
        ]);
      }
    };
    fetchData();
  }, []);

  // Save questions (both add and edit)
  const onAddSylQuestions = async () => {
    const questions = assessments.map((item) => item.question);
    try {
      const data = await addCourseSyl(id, questions);
      alert("Questions Added successfully");
      navigate("/course-master");

      console.log("Questions saved:", data);
    } catch (error) {
      console.error("Error saving questions:", error);
    }
  };

  const onUpdateSylQuestions = async () => {
    const questions = assessments.map((item) => ({
      id: item.id || null,
      question: item.question,
    }));

    try {
      const data = await updateCourseSyl(id, questions);
      alert("Questions updated successfully");
    } catch (error) {
      alert("Error updating questions : ", error);
    }
    navigate("/course-master");
  };

  // Handle drag-and-drop
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(assessments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAssessments(items);
  };

  // Add a new question
  const addQuestion = () => {
    setAssessments([
      ...assessments,
      { id: null, question: "", mandatory: "No" },
    ]);
  };

  // Remove a question
  const removeQuestion = (index) => {
    const updatedAssessments = assessments.filter((_, i) => i !== index);
    setAssessments(updatedAssessments);
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedAssessments = [...assessments];
    updatedAssessments[index][field] = value;
    setAssessments(updatedAssessments);
  };

  return (
    <Card>
      <div className="assessment-form">
        <h3>Assessment</h3>
        <div className="assessment-container">
          {/* Header Row */}
          <div className="assessment-header">
            <span className="drag-placeholder"></span>
            <span className="column-title">Question</span>
            <span className="column-dropdown">Mandatory</span>
            <span className="column-actions"></span>
          </div>

          {/* Question Rows */}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="assessments">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {assessments.map((assessment, index) => (
                    <Draggable
                      key={index}
                      draggableId={`assessment-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="assessment-row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <span
                            {...provided.dragHandleProps}
                            className="drag-handle"
                          >
                            ☰{/* <DragDrop/> */}
                          </span>
                          <input
                            type="text"
                            placeholder="Enter question"
                            value={assessment.question}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "question",
                                e.target.value
                              )
                            }
                          />
                          <select
                            value={assessment.mandatory}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "mandatory",
                                e.target.value
                              )
                            }
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          {!assessment.id ? (
                            <button
                              type="button"
                              className="delete-btn"
                              onClick={() => removeQuestion(index)}
                            >
                              🗑
                            </button>
                          ) : (
                            <div style={{ width: "23px" }} />
                          )}
                          {index === assessments.length - 1 ? (
                            <button
                              type="button"
                              className="add-btn"
                              onClick={addQuestion}
                            >
                              +
                            </button>
                          ) : (
                            <div style={{ width: "23px" }} />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="submit-btn d-flex justify-content-end">
            <Button
              label={!assessments[0]?.id ? "Save" : "Update"}
              backgroundColor={"#f6921e"}
              textColor={"#fff"}
              onClick={
                !assessments[0]?.id ? onAddSylQuestions : onUpdateSylQuestions
              }
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AssessmentForm;
