import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activityMedias : [],
    currentTab: "media",
    currentActivityMedia: {
        media: {},
        logbook: {},
        viva: {}
    },
    deleteActivityMedia: {
        media: {},
        logbook: {},
        viva: {}
    },
    deleteMediaIds : [],
    currentStatus : "Finish",
    studentList : []
}

const activityMediaSlice = createSlice({
    name: 'activityMedia',
    initialState,
    reducers: {
      setCurrentTab: (state, action) => {
        state.currentTab = action.payload
      },
      setActivityMedias: (state, action) => {
        let data = action.payload;
        state.activityMedias = data;
        let media = {}
        let logbook = {}
        let viva = {}
        let temp_student_list = []
        data?.map((ele)=>{
            let Name = ele.first_name + " " + ele.last_name
            if(ele.first_name != "untagged"){
              let student = {
                label : Name,
                value : ele.user_id ? ele.user_id : -1
              }
              temp_student_list.push(student)
            }
            let student_media = []
            let student_logbook = []
            let student_viva = []
            ele?.medias?.map((med)=>{
                med.user_id = ele.user_id
                if(med.media_category == "photo" || med.media_category == "video")
                  student_media.push(med)
                else if (med.media_category == "logsheet")
                    student_logbook.push(med)
                else if (med.media_category == "viva")
                    student_viva.push(med)
            })
            media[Name] = student_media
            logbook[Name] = student_logbook
            viva[Name] = student_viva
        })
        temp_student_list.sort((a, b) => a.label.localeCompare(b.label));
        state.currentActivityMedia = {
            media: media,
            logbook: logbook,
            viva: viva
        }
        state.studentList = temp_student_list
      },
      DeleteMedia: (state, action) => {
        const mediaType = action?.payload?.media_type
        const student = action.payload.student
        const media = action.payload.media

        let currentMedia = {}
        let deleteMedia = {}
        let tempDeleteMediaIds = []

        tempDeleteMediaIds = state.deleteMediaIds
        tempDeleteMediaIds.push(media.media_id)
        state.deleteMediaIds = tempDeleteMediaIds
        
        
          let source = mediaType=="logsheet"? "logbook" : ( (mediaType == "photo" || mediaType == "video") ? "media" : "viva")
          console.log(source)
          currentMedia = state.currentActivityMedia[`${source}`]
          deleteMedia = state.deleteActivityMedia[`${source}`]
          let studentMedia = currentMedia[`${student}`]
        
          let newTempLB = studentMedia.filter(ele=>{
              return ele.media_id != media.media_id
          })
          currentMedia[`${student}`] = newTempLB

          state.currentActivityMedia[`${source}`] = currentMedia
          
          let studentLB = deleteMedia[`${student}`] || []
          if(studentLB?.length > 0){
            studentLB.push(media)
            deleteMedia[`${student}`] = studentLB
          } else {
            studentLB.push(media)
            deleteMedia[`${student}`] = studentLB
          }
          state.deleteActivityMedia[`${source}`] = deleteMedia
      },
      RevertMedia: (state, action) => {
        let media = action?.payload?.media
        let mediaType = action?.payload?.media_type
        let student = action?.payload?.student

        let tempDeleteMediaIds = state.deleteMediaIds
        tempDeleteMediaIds.pop(media.media_id)
        state.deleteMediaIds = tempDeleteMediaIds
        let source = mediaType=="logsheet"? "logbook" : ( (mediaType == "photo" || mediaType == "video") ? "media" : "viva")
          let currentDeletedMedia = state.deleteActivityMedia[`${source}`]
          let currentMediaList = state.currentActivityMedia[`${source}`]
          let deletedStudentMedia = currentDeletedMedia[`${student}`]
          let newTempDeleteMd = deletedStudentMedia.filter(ele=>{
            return ele.media_id != media.media_id
          })

          if(newTempDeleteMd.length > 0)
            currentDeletedMedia[`${student}`] = newTempDeleteMd
          else
            delete currentDeletedMedia[`${student}`]

          let currStuMedia = currentMediaList[`${student}`] || []
          if(currStuMedia.length > 0){
            currStuMedia.push(media)
          } else {
            currStuMedia.push(media)
          }
          currentMediaList[`${student}`] = currStuMedia
          

          state.deleteActivityMedia[`${source}`] = currentDeletedMedia
          state.currentActivityMedia[`${source}`] = currentMediaList
        
      },
      SetStatus: (state, action) => {
        state.currentStatus = action.payload
      },
      SetDeleteActivityMedia : (state, action) => {
        state.deleteActivityMedia.media = {}
        state.deleteActivityMedia.logbook = {}
        state.deleteActivityMedia.viva = {}
        state.deleteMediaIds = []
      }
    }
  });
  
  export const { setActivityMedias, setCurrentTab, DeleteMedia, RevertMedia, SetStatus, SetDeleteActivityMedia } = activityMediaSlice.actions;
  
  export default activityMediaSlice.reducer;
  

