import axios from "axios";
import { DEV_BASE_URL } from "../../../utils/constants/routes";

export const getCourseMaster = async (course_id = "") => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/api/v1/courses/getCourse`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id,
        },
      }
    );

    if (response.status) {
      return response.data.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const addToCourseMaster = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/addCourse`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCourseMasterCourse = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/updateCourse`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

/**************************** ASSESSMENTS ******************************/

export const getCourseAssessment = async (
  course_id = "",
  course_assessment_id = ""
) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/api/v1/courses/getAssessment`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id,
          course_assessment_id,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const addCourseAssessment = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/addAssessment`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCourseAssessment = async (data) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/updateAssessment`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};

/************************************** COURSE DROPDOWN ******************************************/
export const getCircleCourseDropdown = async () => {
  try {
    const response = await axios.get(`${DEV_BASE_URL}/courses?dropdown=true`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status) {
      return response.data.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

/************************************** SYL ADD ******************************************/
export const addCourseSyl = async (course_id, questions) => {
  try {
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/addSylQuestion`,
      {
        syl_questions: questions,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id,
        },
      }
    );
    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
};

/************************************** SYL GET ******************************************/
export const fetchQuestions = async (id) => {
  try {
    const response = await axios.get(
      `${DEV_BASE_URL}/api/v1/courses/getSylQuestion`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id: id,
        },
      }
    );

    // Transform the response to match state format
    const prefilledQuestions = response.data.data.map((item) => ({
      id: item.id,
      question: item.question,
      mandatory: "No", // Assuming "mandatory" isn't in the API response
    }));

    if (prefilledQuestions.length === 0) {
      prefilledQuestions.push({
        id : null,
        question : "",
        mandatory : "No"
      })
    }

    console.log(prefilledQuestions)

    // setAssessments(prefilledQuestions);
    return prefilledQuestions;
  } catch (error) {
    console.error("Error fetching questions:", error);
  }
};

/************************************** SYL UPDATE ******************************************/
export const updateCourseSyl = async (course_id, questions) => {
  try {
    console.log("course_id", course_id);
    console.log("questions", questions);
    const response = await axios.post(
      `${DEV_BASE_URL}/api/v1/courses/updateSylQuestion`,
      {
        syl_questions: questions,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          course_id,
        },
      }
    );
    if (response.status) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
};
