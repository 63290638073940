// src/features/activity/activityList.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/Card";
import Table from "../../components/Table/Table";
import { cancelAppointment, getTicketsAPI } from "./ticketsService";
import { setSelectedTicket, setTickets } from "./ticketsSlice";
import { formatToIST, formatDateString } from "../../utils/formatter";
import "./ticketsList.scss";
import { closeModal, openModal } from "../../components/Modal/ModalSlice";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";
import { MdTimelapse } from "react-icons/md";
import CalendarGrid from "../../components/Calendar/CalendarGrid";
import CalendarReact from "../../components/Calendar/CalendarReact";
import TimeSlots from "../../components/TimeSlots/TimeSlots";
import { SortOrder } from "react-data-table-component";
import DropdownButton from "../../components/DropdownButton";

const TicketsList = () => {
  const [callApi, setCallApi] = useState(true);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const ticketStatusList = [
    "UPCOMING",
    // "SCHEDULED",
    "COMPLETED",
    "CANCELLED",
    // "RESCHEDULED",
  ];

  const tickets = useSelector((state) => state.tickets.tickets);
  const [selectedValues, setSelectedValues] = useState({});

  const handleChange = (event, row) => {
    const value = event.target.value;
    console.log(row);
    dispatch(setSelectedTicket(row));

    // Update the selected value for the specific row
    setSelectedValues({
      ...selectedValues,
      [row.id]: value,
    });

    if (value === "join") {
      // Open the meeting link in a new tab
      window.open(row.meeting_link, "_blank");
    } else {
      // Show modal confirmation for other actions
      dispatch(openModal(`${value}_modal`));
    }
  };

  useEffect(() => {
    const fetchTickets = async () => {
      const data = await getTicketsAPI(page, rowsPerPage);
      dispatch(setTickets(data.data.appointments));
      // console.log(data.data.appointments);
      setTotalRows(data.data.total_records);
    };
    fetchTickets();
    setCallApi(true);
  }, [callApi, page, rowsPerPage]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      SortOrder: "desc",
      width: "100px",
    },
    {
      name: "Member Name",
      selector: (row) => row.name,
      sortable: true,
      width: "162px",
    },
    {
      name: "Purpose",
      selector: (row) => row.purpose,
      sortable: true,
      width: "162px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => (
        <span
          className="text-truncate tickets-description"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={row.description}
        >
          {row.description}
        </span>
      ),
    },
    {
      name: "Date & Time",
      cell: (row) => (
        <span
          className="text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title={formatDateString(row.start_time)}
        >
          {formatDateString(row.start_time)}
        </span>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <span className={`status ${row.status.toLowerCase()} text-nowrap`}>
          {row.status}
        </span>
      ),
      sortable: true,
      width: "160px !important",
    },
    {
      name: "Action",
      cell: (row) => {
        return row.status === "scheduled" || row.status === "rescheduled" ? (
          // bootstrap dropdown containing join, cancel and reschedule
          // <select
          //   className="form-select ticket-action-select"
          //   aria-label="Action Select"
          //   value={selectedValues[row.id]}
          //   onChange={(e) => handleChange(e, row)}
          // >
          //   <option defaultValue>Select Action</option>
          //   <option value="join">Join</option>
          //   <option value="reschedule">Reschedule</option>
          //   <option value="cancel">Cancel</option>
          // </select>
          <DropdownButton
            dropDownBtnOptions={[
              {
                label: "Join",
                onClick: () => {
                  dispatch(setSelectedTicket(row));
                  window.open(
                    row.meeting_link,
                    "_blank",
                    "noopener,noreferrer"
                  );
                },
              },
              {
                label: "Reschedule",
                onClick: () => {
                  dispatch(setSelectedTicket(row));
                  dispatch(openModal("reschedule_modal"));
                },
              },
              {
                label: "Cancel",
                onClick: () => {
                  dispatch(setSelectedTicket(row));
                  dispatch(openModal("cancel_modal"));
                },
              },
            ]}
          />
        ) : null;
      },
      width: "160px",
    },
  ];

  const onRowClick = (row) => {
    dispatch(setSelectedTicket(row));
  };

  return (
    <div className="tickets-page">
      <Card>
        {/* <TableComponent data={activities} />
         */}
        <Table
          entity="tickets"
          data={tickets}
          columns={columns}
          statusList={ticketStatusList}
          hasAddBtn={false}
          onRowClick={onRowClick}
          isSupportTickets={true}
          // isServerSidePagination
          // setPage={setPage}
          // setRowsPerPage={setRowsPerPage}
          // paginationTotalRows={totalRows}
        />

        <TicketListingModal callApi={callApi} setCallApi={setCallApi} />
      </Card>
    </div>
  );
};

export default TicketsList;

export const TicketListingModal = ({ fromDetails, callApi, setCallApi }) => {
  const dispatch = useDispatch();
  const modalType = useSelector((state) => state.modal.modalType);
  const selectedTicket = useSelector((state) => state.tickets.selectedTicket);
  const onReschedule = () => {
    dispatch(closeModal());
    dispatch(openModal("reschedule_final_modal"));
  };
  const onCancel = async () => {
    const data = await cancelAppointment(
      selectedTicket.id,
      selectedTicket.external_event_id
    );
    dispatch(closeModal());
    !fromDetails ? setCallApi(!callApi) : window.location.replace("/tickets");
    // window.location.replace("/tickets");
  };
  switch (modalType) {
    case "reschedule_modal":
      return <ConfirmationModal title="Reschedule" onSubmit={onReschedule} />;
    case "cancel_modal":
      return <ConfirmationModal title="Cancel" onSubmit={onCancel} />;
    case "reschedule_final_modal":
      return (
        <RescheduleContentModal
          title="Reschedule"
          calendarType="book_call"
          fromDetails={fromDetails}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      );
    default:
      return null;
  }
};

export const ConfirmationModal = ({ title, onSubmit }) => {
  const dispatch = useDispatch();
  return (
    <Modal title={title} className="modal-content2-sm">
      <div>
        <h5>Are you sure you want to {title.toLowerCase()}?</h5>
        <p>Please click on “Confirm” to continue.</p>
      </div>
      <div className="d-flex justify-content-end gap-3 mt-3 mb-3">
        <Button
          label="Confirm"
          onClick={() => {
            dispatch(closeModal());
            onSubmit && onSubmit();
          }}
          backgroundColor={"#EA983E"}
          textColor={"white"}
        />
      </div>
    </Modal>
  );
};

export const RescheduleContentModal = ({
  title,
  calendarType,
  fromDetails,
  setCallApi,
  callApi,
}) => {
  const selectedTicket = useSelector((state) => state.tickets.selectedTicket);
  const [rescheduleDescription, setRescheduleDescription] = useState(
    selectedTicket.description
  );
  return (
    <Modal title={title} className="">
      <h5 className="ms-4">Scheduler</h5>
      <div className="d-flex justify-content-start align-items-start  gap-2">
        <span className="timer-icon ">
          <MdTimelapse />
        </span>
        <p className=""> 30 min appointments</p>
      </div>

      <div className="d-flex justify-content-start gap-5 h-100 mt-3 mb-5 w-75 mx-auto">
        {/* <CalendarGrid /> */}
        <div className="d-flex flex-column">
          <CalendarReact />
          <div className="reschedule-description mt-3">
            <label htmlFor="rescheduleDescription" className="form-label">
              Description
            </label>
            <textarea
              name="rescheduleDescription"
              value={rescheduleDescription}
              className="form-control"
              placeholder="
              Enter Description"
              onChange={(e) => setRescheduleDescription(e.target.value)}
            />
          </div>
        </div>
        <TimeSlots
          rescheduleDescription={rescheduleDescription}
          calendarType={calendarType}
          fromDetails={fromDetails}
          setCallApi={setCallApi}
          callApi={callApi}
        />

        {/* <CalendarGrid /> */}
      </div>
    </Modal>
  );
};
